import React from 'react';
import { useParams } from 'react-router-dom';
import MovieList from './MovieList';

const MovieListByTag = ({ movies, onSelectMovie }) => {
  const { tag } = useParams();
  const filteredMovies = movies.filter(movie => movie.tags.includes(tag));

  return (
    <div>
      <h2 style={{color:"white"}}>Videos from {decodeURIComponent(tag)}</h2>
      <MovieList movies={filteredMovies} onSelectMovie = {onSelectMovie} />
    </div>
  );
};

export default MovieListByTag;
