import React, { useRef, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './MoviePlayer.css';
import MovieList from './MovieList';

const MoviePlayer = ({ movies }) => {
  const { title } = useParams();
  const navigate = useNavigate();
  const videoRef = useRef(null);

  const [clickCount, setClickCount] = useState(0);

  const handleSelectMovie = (movie) => {
    const encodedTitle = encodeURIComponent(movie.title);
    navigate(`/movie/${encodedTitle}`);
  };

  const handleTagClick = (tag) => {
    navigate(`/movies/tag/${encodeURIComponent(tag)}`);
  };

  useEffect(() => {
    setClickCount(0); // Reset click count when the movie title changes
  }, [title]);

  const selectedMovie = movies.find(movie => movie.title === decodeURIComponent(title));

  // useEffect(() => {
  //   if (selectedMovie && videoRef.current) {
  //     setTimeout(() => {
  //       videoRef.current.currentTime = 70; // Skip the first 10 seconds
  //     }, 1000);
  //   }
  // }, [selectedMovie]);

  if (!selectedMovie) return <div>Loading...</div>;
  
  // Logic for filtering and sorting recommended movies
  const first10Chars = selectedMovie.Fulltitle.slice(0, 10);
  const matchingMovies = movies.filter(movie => 
    movie.Fulltitle.slice(0, 10) === first10Chars && movie.Fulltitle !== selectedMovie.title
  );

  const nonMatchingMovies = movies.filter(movie => 
    movie.Fulltitle.slice(0, 10) !== first10Chars && movie.Fulltitle !== selectedMovie.Fulltitle
  );

  const sortedMovies = [...matchingMovies, ...nonMatchingMovies].slice(0, 10); // Limit to 10 movies

  const isVideo = selectedMovie.iframeSrc && selectedMovie.iframeSrc.startsWith("https://filsrol.com");
  const isVideo2 = selectedMovie.iframeSrc && selectedMovie.iframeSrc.startsWith("https://nmcorp.video");

  const handleDownloadClick = () => {
    if (clickCount < 2) {
      // First or second click: redirect to ad URL
      window.open('https://eweconciliate.com/kxmzskh900?key=a6531be8235dcc200df4375f01726883', '_blank');
      setClickCount(prevCount => prevCount + 1);
    } else {
      // After two clicks: redirect to the actual download URL
      window.open(selectedMovie.downloadUrl, '_blank');
    }
  };

  return (
    <div className="movie-player">
      <h4>{selectedMovie.Fulltitle || 'Untitled'}</h4>
      {(isVideo||isVideo2) ? (
        <iframe
          src={selectedMovie.iframeSrc}
          title={selectedMovie.title}
          allowFullScreen
          style={{ width: '100%', height: '300px' }}
        ></iframe>
      ) : (
        <video
          ref={videoRef}
          src={selectedMovie.iframeSrc}
          title={selectedMovie.title}
          autoPlay
          controls
          style={{ width: '100%', height: '300px' }}
        >
          Your browser does not support the video tag.
        </video>
      )}

      <div className="movie-player-buttons">
        {selectedMovie.downloadUrl && (
          <>
            <button className="movie-button-download" onClick={handleDownloadClick}>
              Download
            </button>
            {clickCount < 3 && (
              <span className="click-instructions">
                Click {3 - clickCount} time{clickCount === 2 ? "" : "s"} to get the final download link.
              </span>
            )}
          </>
        )}
      </div>

      <div className="movie-tags">
        {selectedMovie.tags && selectedMovie.tags.map((tag, index) => (
          <button
            key={index}
            className="tag-button"
            onClick={() => handleTagClick(tag)}
          >
            {tag}
          </button>
        ))}
      </div>

      <h3 className="recommended-videos-title">Recommended Videos</h3>
      <MovieList movies={sortedMovies} onSelectMovie={handleSelectMovie} />
    </div>
  );
};

export default MoviePlayer;
